<template>
  <div>
    <TitleMore
      :title="['直属单位']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <div class="itemText" style="margin-bottom: 10px;" v-for="item in ListData" :key="item.id"  @click="$goto('/detailShow',{id:item.id})">
        <p>
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>

      </div>
    </div>
  </div>
</template>
 
<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
  data() {
    return {
      ListData:[]
    };
  },

  components: {
    TitleMore,
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      apiUrls.selfDynamicList({ node: "zsdw", size: 100 }).then((res) => {
        this.ListData = res.results.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.itemTextContent{
	&:hover {
		color: #d1292e;
	}
}
</style>